import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sometimes you just have a feeling`}</h2>
    <p>{`Hi there. I'm `}<a href="https://mike.biful.co" target="_blank" rel="noopener noreferrer">{`Mike Bifulco`}</a>{`, the creator of Pistola. I'm building Pistola to help everyone (`}<em parentName="p">{`everyone`}</em>{`) who tracks their cycling, running, walking, and other workout activities using GPS, Heart Rate, and other sensors.`}</p>
    <p>{`Pistola is here for every marathon runner, couch-to-5k newbie, fixie-enthusiast, every cyclist, the hardened ultra-marathon runner, every cyclocrosser, XC racer, triathletes, every recumbent-pilot, the fat-bike mini-tourer, hikers, and the long-haul bikepacker (and everyone else, too -- I'm sure you get it by now).`}</p>
    <p>{`Pistola is entering the fray as a user-feedback driven, radically transperent service to help athletes of all types understand their workout results more readily. You're likely to have encountered other services like Map My Fitness, Strava, and Endomondo - I`}</p>
    <p>{`It's no secret that cycling draws an empassioned demographic. Everyone who has experienced cycling in any form has felt something special.`}</p>
    <h2>{`Enter Zwift`}</h2>
    <p>{`Enter `}<a href="https://zwift.com" target="_blank" rel="noopener noreferrer">{`Zwift`}</a>{`, the feverishly popular MMO app for indoor cycling training, racing, and casual riding. If you've ridden on zwift, you know how challenging it can be to ride on a trainer. In the span of 30 minutes, you can fit in an absolutely brutal workout that will teach you a lot about your own limits. Zwift has done a great job of gamifying something that many cyclists used to hate. In fact, I know a guy who used to refer to his Computrainer as "Satan". No joke!`}</p>
    <p>{`A good litmus for the size and passion of the community of riders on Zwift is the in-game course Alpe Du Zwift. The most challenging route in Zwift, it is a seven-mile long climb up a virtual mountain that climbs 3753ft of elevation. It is a virtual mirror of the Alpe d’Huez, a grueling climb ridden by World Tour racers in the Tour de France.`}</p>
    <iframe height="405" width="590" frameBorder="0" allowtransparency="true" scrolling="no" src="https://www.strava.com/segments/17267489/embed"></iframe>
    <p>{`At the time of writing, the top reported time up Alpe Du Zwift is `}<strong parentName="p">{`33 minutes`}</strong>{` from base to peak. On the other side of the spectrum, the Alpe can take slower riders up to `}<strong parentName="p">{`three and a half `}<em parentName="strong">{`hours`}</em></strong>{` to complete. That's an incredible delta, and it speaks to the incredible capacity of the human body just as well as the vast breath of writer abilities on Zwift.`}</p>
    <p>{`After riding the course, every one of these riders will have felt a massive sense of accomplishment. Likewise, they will all have wondered how they can do better -- that's where Pistola comes in.`}</p>
    <p>{`As a member of this community myself. I've noticed that there is a massive gulf of expertise for folks who ride indoors. Many don't really understand how they're being measured when they ride, and what power numbers, calorie measurements, and heart rate zones really mean.`}</p>
    <p>{`The inspiration for pistola originally was from a thread I saw on Facebook. Someone posted about a ride they had analyzed with both Garmin Connect and Strava. For that single ride, one service showed 130 calories burned and the other showed 800 calories burned. That difference between the two rides is unacceptably large and befuddling for the average rider who is not a data geek.`}</p>
    <p>{`My goal with Pistola is to help people better understand how many calories they've burned during a ride. I think we can help riders understand how confident we can be in the data that they've recorded, as well as what changes they can make to in order to increase the quality of data they’re recording.`}</p>
    <h3>{`Calories are a lie`}</h3>
    <p>{`As far as I can tell, no service out there will tell you that when they show you calories burned for a workout, it’s really just an estimate. It should come as no surprise that the human body is vastly complex, and while there are armies of researchers trying to nail down a trustworthy formula for calories burned during a workout, nobody has really nailed it yet.`}</p>
    <p>{`I’m also a bit suspicious that some services intentionally inflate calorie numbers when they show them to users. Think about it - if one service says you burn 400 calories, and the other says 600, you’re more likely to favor the latter service when you’re looking at your workouts. It’s a cheap and dishonest way to buy loyalty. At the moment, this is just a theory of mine - but it would explain why services have such wildly different results.`}</p>
    <p>{`Put simply, I want Pistola to be more than just a complex analysis of your ride data. I want you to be able to use Pistola to look at your ride more like a story, and less like an inscrutable spreadsheet full of statistics that may hold no meaning for you.`}</p>
    <h3>{`Early access`}</h3>
    <p>{`In the coming weeks, we’ll open up early access to Pistola for a select number of users. If you’re interested in using Pistola during its early days, fill out the sign up form at the end of this post. In the meantime, if you’re a member of the cycling community and you have needs that are unfulfilled by your current tracking software, your feedback is always welcome.`}</p>
    <p>{`Positive or negative any criticism is always interesting, and I’d love to hear it. I’m looking forward to helping you out and discovering how we can ride better together.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      